<template>
  <div>
    <div class="page-header page-header-small">
      <parallax class="page-header-image" :style="backgroundTopo"></parallax>
      <div class="content-center">
        <div class="content-center brand">
          <h2 class="h1-seo">Criar demanda</h2>
        </div>
      </div>
    </div>

    <div class="section section-contact-us text-center">
      <div class="container">
        <form @submit.prevent="onSubmit" class="col-md-8 ml-auto mr-auto text-center">
          <p class="description">Informe abaixo os dados da demanda.</p>

          <div>
            <b-alert :show="showPagamento" variant="warning">
              <font-awesome-icon icon="circle-exclamation" class="mr-2" />
              <b>Atenção!</b>
              <br />Esse tipo de demanda pode gerar um valor adicional para análise.
            </b-alert>
          </div>

          <b-card>
            <b-row>
              <b-col cols="6" v-if="!hasHiddenField('objetivo')">
                <b-form-group>
                  <b-form-select
                    :state="validateState('objetivo')"
                    class="form-control"
                    id="objetivo"
                    name="objetivo"
                    v-model="objetivo"
                    v-validate="'required'"
                    aria-describedby="objetivo-feedback"
                    @change="showAlertPagamento()"
                  >
                    <option :value="null">{{ labelSelecionarObjetivo }}</option>
                    <option v-for="(objetivo, idx) in getObjetivosByGrupo()" :key="idx" :value="objetivo.value.id">
                      {{ objetivo.text }}
                    </option>
                  </b-form-select>

                  <b-form-invalid-feedback id="objetivo-feedback">{{ labelInformarObjetivo }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col v-if="!hasHiddenField('tipo')">
                <b-input-group>
                  <b-form-select
                    :options="getTipos()"
                    :state="validateState('tipo')"
                    class="form-control"
                    id="tipo"
                    name="tipo"
                    v-model="tipo"
                    v-validate="'required'"
                    aria-describedby="tipo-feedback"
                    @change="getTiposArquivos()"
                  ></b-form-select>

                  <b-form-invalid-feedback id="tipo-feedback">Selecione o 'Tipo' da demanda.</b-form-invalid-feedback>
                  <b-button
                    v-if="tipo !== null && tipo.idAnexo !== undefined && tipo.idAnexo !== null"
                    class="ml-1"
                    variant="secondary"
                    @click="getDownloadAnexoTipo()"
                  >
                    <font-awesome-icon icon="circle-question" />
                  </b-button>
                </b-input-group>
              </b-col>
              <b-col cols="6" v-if="!hasHiddenField('area')">
                <!-- Validacao: :state="validateState('area')" aria-describedby="area-feedback" -->
                <b-form-group>
                  <b-form-select
                    :options="areas"
                    class="form-control"
                    id="area"
                    name="area"
                    v-model="area"
                  ></b-form-select>

                  <b-form-invalid-feedback id="area-feedback">Selecione a 'Área' da demanda.</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" v-if="!hasHiddenField('titulo')">
                <b-form-group>
                  <b-form-input
                    :state="validateState('titulo')"
                    id="titulo"
                    name="titulo"
                    placeholder="Título/descrição"
                    v-model="titulo"
                    v-validate="'required'"
                    aria-describedby="titulo-feedback"
                    maxlength="50"
                    autofocus
                  ></b-form-input>

                  <b-form-invalid-feedback id="titulo-feedback"
                    >É necessário informar o campo 'Título'.</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>

              <b-col cols="12" v-if="!hasHiddenField('cidade')">
                <b-form-group>
                  <b-form-select
                    class="form-control"
                    id="cidade"
                    name="cidade"
                    v-model="cidade"
                    aria-describedby="cidade-feedback"
                    @change="handleSelectCidade"
                  >
                    <option :value="null">Selecione a Cidade</option>
                    <optgroup
                      v-if="trecho == null"
                      v-for="(rdv, idxRdv) in getRodoviasByTrechos()"
                      :key="idxRdv"
                      :label="rdv"
                    >
                      <option v-for="(cidade, idx) in getCidadesByRodovia(rdv)" :key="idx" :value="cidade.value">
                        {{ cidade.text }}
                      </option>
                    </optgroup>
                    <option
                      v-if="trecho != null"
                      v-for="(cidade, idx) in getCidades()"
                      :key="idx"
                      :value="cidade.value"
                    >
                      {{ cidade.text }}
                    </option>
                  </b-form-select>

                  <b-form-invalid-feedback id="cidade-feedback">Informe a 'Cidade' da demanda.</b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col cols="8" v-if="!hasHiddenField('rodovia')">
                <b-form-group>
                  <b-form-select
                    :state="validateState('trecho')"
                    class="form-control"
                    id="trecho"
                    name="trecho"
                    v-model="trecho"
                    v-validate="'required'"
                    aria-describedby="trecho-feedback"
                  >
                    <option :value="null">Selecione a Rodovia</option>
                    <optgroup v-for="(rodovia, idx) in getRodoviasByTrechos()" :key="idx" :label="rodovia">
                      <option v-for="(trecho, idxTre) in getTrechosByRodovia(rodovia)" :key="idxTre" :value="trecho.id">
                        {{ getTrechoInfo(trecho) }}
                      </option>
                    </optgroup>
                  </b-form-select>
                  <b-form-invalid-feedback id="trecho-feedback"
                    >Informe a 'Rodovia' da demanda.</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>

              <b-col cols="4" v-if="!hasHiddenField('sentido')">
                <b-form-group>
                  <b-form-select
                    class="form-control"
                    id="sentido"
                    name="sentido"
                    v-model="sentido"
                    aria-describedby="sentido-feedback"
                  >
                    <option :value="null">Selecione o Sentido</option>
                    <option v-for="(sentido, idxSent) in fieldSentido" :key="idxSent" :value="idxSent.value">
                      {{ sentido.text }}
                    </option>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="3" v-if="trecho == undefined && !hasHiddenField('kmInicial')">
                <b-form-group>
                  <vue-numeric
                    class="direita form-control"
                    :precision="3"
                    :state="errorKmInicial == null"
                    id="kmInicial"
                    name="kmInicial"
                    placeholder="KM Inicial"
                    separator="."
                    thousand-separator="."
                    decimal-separator=","
                    v-model="kmInicial"
                    @change="changeKmInicial"
                    aria-describedby="kmIni-feedback"
                  ></vue-numeric>

                  <b-form-invalid-feedback :state="errorKmInicial == null" id="kmIni-feedback">{{
                    errorKmInicial
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col cols="3" v-if="trecho != undefined && !hasHiddenField('kmInicial')">
                <b-input-group>
                  <vue-numeric
                    class="direita form-control"
                    :precision="3"
                    :state="errorKmInicial == null"
                    id="kmInicial"
                    name="kmInicial"
                    placeholder="KM Inicial"
                    separator="."
                    thousand-separator="."
                    decimal-separator=","
                    v-model="kmInicial"
                    @change="changeKmInicial"
                    aria-describedby="kmIni-feedback"
                  ></vue-numeric>

                  <b-input-group-append>
                    <b-button variant="primary" @click="openMapForChooseKm('I')">
                      <i v-if="!loadingCoordRodKmInicial" class="now-ui-icons location_pin" />
                      <b-spinner v-else style="width: 0.8rem; height: 0.8rem;"></b-spinner>
                    </b-button>
                  </b-input-group-append>

                  <b-form-invalid-feedback :state="errorKmInicial == null" id="kmIni-feedback">{{
                    errorKmInicial
                  }}</b-form-invalid-feedback>
                </b-input-group>
              </b-col>

              <b-col cols="3" v-if="trecho == undefined && !hasHiddenField('kmFinal')">
                <b-form-group>
                  <vue-numeric
                    class="direita form-control"
                    :precision="3"
                    :state="errorKmFinal == null"
                    id="kmFinal"
                    name="kmFinal"
                    placeholder="KM Final"
                    separator="."
                    thousand-separator="."
                    decimal-separator=","
                    v-model="kmFinal"
                    @change="changeKmFinal"
                    aria-describedby="kmFim-feedback"
                  ></vue-numeric>

                  <b-form-invalid-feedback :state="errorKmFinal == null" id="kmFim-feedback">{{
                    errorKmFinal
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col cols="3" v-if="trecho != undefined && !hasHiddenField('kmFinal')">
                <b-input-group>
                  <vue-numeric
                    class="direita form-control"
                    :precision="3"
                    :state="errorKmFinal == null"
                    id="kmFinal"
                    name="kmFinal"
                    placeholder="KM Final"
                    separator="."
                    thousand-separator="."
                    decimal-separator=","
                    v-model="kmFinal"
                    @change="changeKmFinal"
                    aria-describedby="kmFim-feedback"
                  ></vue-numeric>

                  <b-input-group-append>
                    <b-button variant="primary" @click="openMapForChooseKm('F')">
                      <i v-if="!loadingCoordRodKmFinal" class="now-ui-icons location_pin" />
                      <b-spinner v-else style="width: 0.8rem; height: 0.8rem;"></b-spinner>
                    </b-button>
                  </b-input-group-append>

                  <b-form-invalid-feedback :state="errorKmFinal == null" id="kmFim-feedback">{{
                    errorKmFinal
                  }}</b-form-invalid-feedback>
                </b-input-group>
              </b-col>

              <b-col cols="6" v-if="!hasHiddenField('local')">
                <!-- Validação: :state="validateState('local')" -->
                <b-form-group>
                  <b-form-select
                    :options="locais"
                    class="form-control"
                    id="local"
                    name="local"
                    v-model="local"
                    aria-describedby="local-feedback"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col cols="6" v-if="!hasHiddenField('nomenclaturaAlca')">
                <b-form-group>
                  <b-form-select
                    :options="alcas"
                    :state="validateState('alcas')"
                    class="form-control"
                    id="alca"
                    name="alca"
                    v-model="alca"
                    aria-describedby="alca-feedback"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col cols="6" v-if="!hasHiddenField('faixa')">
                <b-form-group>
                  <b-form-select
                    :options="faixas"
                    :state="validateState('faixa')"
                    class="form-control"
                    id="faixa"
                    name="faixa"
                    v-model="faixa"
                    aria-describedby="faixa-feedback"
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" v-if="!hasHiddenField('observacao')">
                <b-form-group>
                  <b-form-textarea
                    class="form-control"
                    name="name"
                    rows="2"
                    cols="80"
                    placeholder="Observações..."
                    v-model="observacao"
                    maxlength="255"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
              <b-col v-if="tipo != null" cols="12">
                <b-table
                  :show-empty="true"
                  :fields="fieldsAnexos"
                  :items="anexos"
                  empty-text="Não há dados disponíveis."
                >
                  <template #cell(nomeArquivo)="row">
                    <div v-if="row.item.obrigatorio == 1" class="font-weight-bold">
                      {{ row.item.nomeArquivo }}
                    </div>
                    <div v-else>
                      {{ row.item.nomeArquivo }}
                    </div>
                  </template>

                  <template #cell(tipoArquivo)="row">
                    {{ getTipoArquivo(row.item) }}
                  </template>

                  <template #cell(arquivo)="row">
                    <div v-if="row.item.obrigatorio == 1">
                      <b-form-file
                        v-model="uploadedFile"
                        placeholder="Selecione o arquivo"
                        browse-text="Escolher"
                        :state="validateState('arquivo-' + row.item.id.sequencia)"
                        :id="'arquivo-' + row.item.id.sequencia"
                        :name="'arquivo-' + row.item.id.sequencia"
                        v-validate="'required'"
                        :aria-describedby="'arquivo-feedback-' + row.item.id.sequencia"
                        :accept="getTypeFile(row.item.tipoArquivo)"
                        @input="onUploadAnexos(uploadedFile, row.item.id.sequencia)"
                      >
                      </b-form-file>

                      <b-form-invalid-feedback :id="'arquivo-feedback-' + row.item.id.sequencia">
                        Adicione um arquivo
                      </b-form-invalid-feedback>
                    </div>
                    <div v-else>
                      <b-form-file
                        v-model="uploadedFile"
                        placeholder="Selecione o arquivo"
                        browse-text="Escolher"
                        :id="'arquivo-' + row.item.id.sequencia"
                        :name="'arquivo-' + row.item.id.sequencia"
                        :accept="getTypeFile(row.item.tipoArquivo)"
                        @input="onUploadAnexos(uploadedFile, row.item.id.sequencia)"
                      >
                      </b-form-file>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card>
          <b-row>
            <b-col :cols="2"></b-col>
            <b-col cols="8">
              <div class="send-button">
                <b-button
                  :disabled="veeErrors.any() && busySave == false"
                  class="btn-round"
                  type="submit"
                  variant="primary"
                  block
                  size="lg"
                >
                  <div v-if="busySave == false">Enviar solicitação</div>
                  <div v-else><b-spinner small /></div>
                </b-button>
              </div>
            </b-col>
          </b-row>

          <b-modal id="confirm-modal" hide-footer>
            <template slot="modal-title">
              Solicitação incluída
            </template>
            <div class="d-block text-center">
              <div class="mx-3">
                <b-alert :show="showPagamento" variant="warning">
                  <i class="fa-solid fa-circle-exclamation"></i> Esse tipo de demanda pode gerar um valor adicional para
                  análise.
                </b-alert>
                <div v-if="refCodeDemandas != undefined" class="mx-2">
                  <a :href="refCodeDemandas.value" target="_blank" rel="noopener noreferrer">Veja o valor a ser pago</a>
                </div>
              </div>

              Sua solicitação foi realizada com sucesso.
              <br />
              <span>N&deg; Protocolo: {{ protocolo }}</span>
            </div>
            <b-button class="mt-2" block @click="hideModal">Fechar</b-button>
          </b-modal>

          <b-modal id="error-modal" hide-footer>
            <template slot="modal-title"
              >Erro incluindo Solicitação</template
            >
            <div class="d-block text-center">Não foi possível registrar sua solicitação.</div>
            <b-button class="mt-2" block @click="hideModalError">Fechar</b-button>
          </b-modal>

          <b-modal
            id="modalChooseKm"
            size="xl"
            :title="chooseKm == 'I' ? 'Escolha do km inicial' : 'Escolha do km final'"
            hide-footer
          >
            <gmaps-map :options="mapOptions" :style="mapStyle" :zoom="10">
              <gmaps-polyline
                :path="coordenadasRodovia"
                strokeColor="blue"
                strokeWeight="15"
                strokeOpacity="0.3"
                @click="ChooseKmMap"
              />
            </gmaps-map>
          </b-modal>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueNumeric from 'vue-numeric';
import { Badge, Button, FormGroupInput } from '@/components';
import { mapState } from 'vuex';
import { gmapsMap, gmapsPolyline } from 'x5-gmaps';

import moment from 'moment';

import numeral from 'numeral';
import 'numeral/locales';

export default {
  name: 'novaDemanda',
  bodyClass: 'novaDemanda-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    [Badge.name]: Badge,
    VueNumeric,
    gmapsMap,
    gmapsPolyline
  },
  data() {
    return {
      backgroundTopo: "background-image: url('/img/solicitacao.jpg')",
      tipos: [{ value: null, text: 'Selecione o Tipo' }],
      areas: [{ value: null, text: 'Selecione a Área' }],
      objetivos: [{ value: null, text: this.labelSelecionarObjetivo }],
      locais: [{ value: null, text: 'Selecione o Local' }],
      alcas: [{ value: null, text: 'Nomenclatura da Alça' }],
      faixas: [{ value: null, text: 'Selecione a Faixa' }],
      cidades: [], // Array of cidades
      trechos: [], // Array of trechos
      hiddenFields: Vue.prototype.$externalConfig.HIDDEN_FIELDS,
      fieldSentido: Vue.prototype.$externalConfig.FIELD_SENTIDO,
      titulo: '',
      tipo: null,
      area: null,
      objetivo: null,
      cidade: null,
      trecho: null,
      trechoCidade: null, // helper para filtrar trechos x cidade
      kmInicial: '',
      kmFinal: '',
      sentido: null,
      local: null,
      alca: null,
      faixa: 'FAIXA_1',
      observacao: '',
      files: [],
      protocolo: '',
      tiposAnexo: [],
      labelSelecionarObjetivo: 'Selecione o Objetivo',
      labelInformarObjetivo: "Informe a 'Objetivo' da demanda.",
      labelSelecionarTipo: 'Selecione o Tipo',
      labelInformarTipo: "Informe o 'Tipo' da demanda",
      errorKmInicial: null,
      errorKmFinal: null,

      busySave: false,
      showPagamento: false,
      refCodeDemandas: {},

      anexos: [],
      fieldsAnexos: [
        { key: 'nomeArquivo', label: 'Nome/Descrição do arquivo' },
        { key: 'arquivo', label: 'Arquivo' },
        { key: 'tipoArquivo', label: 'Tipo' }
      ],
      uploadedFile: null,
      optionsArquivo: [
        {
          id: 1,
          descricao: 'Arquivo PDF'
        },
        {
          id: 2,
          descricao: 'Documento Texto (DOCX)'
        },
        {
          id: 3,
          descricao: 'Planilha Eletrônica (XLSX)'
        },
        {
          id: 4,
          descricao: 'Imagem (PNG/JPG/BMP)'
        },
        {
          id: 5,
          descricao: 'PowerPoint (PPT)'
        },
        {
          id: 6,
          descricao: 'E-mail (MSG/EML)'
        },
        {
          id: 7,
          descricao: 'Arquivo compactado (ZIP/RAR)'
        },
        {
          id: 8,
          descricao: 'Projeto CAD (DWG/DXF)'
        },
        {
          id: 9,
          descricao: 'Arquivo KMZ'
        }
      ],

      // Modal mapa
      chooseKm: undefined,
      mapOptions: {
        center: { lat: -23.550278, lng: -46.633889 },
        zoom: 10,
        zoomControl: true,
        mapTypeId: 'satellite',
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false
      },
      mapStyle: 'width: 99%; height: 500px;',
      coordenadasRodovia: [],
      rodovias: [],
      loadingCoordRodKmInicial: false,
      loadingCoordRodKmFinal: false
    };
  },
  computed: {
    ...mapState('empresa', ['empresas', 'empresaAtual'])
  },
  mounted() {
    if (window.config) {
      if (window.config.LABELS) {
        this.labelSelecionarObjetivo = window.config.LABELS.novaDemanda.selecionarObjetivo;
        this.labelSelecionarTipo = window.config.LABELS.novaDemanda.selecionarTipo;
        this.labelInformarObjetivo = window.config.LABELS.novaDemanda.informarObjetivo;
        this.labelInformarTipo = window.config.LABELS.novaDemanda.informarTipo;
      }

      if (window.config.SIGLA_ASSETS) {
        const siglaAssets = window.config.SIGLA_ASSETS;
        this.backgroundTopo = `background-image: url('/img/${siglaAssets}/solicitacao.jpg')`;
      }
    }

    numeral.locale('pt-br');

    this.fillData();
  },
  methods: {
    changeKmInicial() {
      const formatter = numeral(this.kmInicial);
      this.kmInicial = formatter.format('##[.]###');

      this.errorKmInicial = null;
    },
    changeKmFinal() {
      const formatter = numeral(this.kmFinal);
      this.kmFinal = formatter.format('##[.]###');

      this.errorKmFinal = null;
    },
    fillData() {
      const sigla = this.$store.state.empresa.empresaAtual.sigla;
      const token = this.$store.state.account.user;
      const options = { headers: { Authorization: `Bearer ${token}` } };

      this.tipos = [{ value: null, text: this.labelSelecionarTipo }];
      this.areas = [{ value: null, text: 'Selecione a Área' }];
      this.objetivos = [{ value: null, text: 'Selecione o Objetivo' }];
      this.locais = [{ value: null, text: 'Selecione o Local' }];
      this.alcas = [{ value: null, text: 'Nomenclatura da Alça' }];
      this.faixas = [{ value: null, text: 'Selecione a Faixa' }];

      this.cidades = []; // Array of cidades
      this.trechos = []; // array of trechos

      // Fill Locais
      this.locais.push({ value: 'EIXO_TRONCAL', text: 'Eixo Troncal' });
      this.locais.push({ value: 'MARGINAL_DIREITA', text: 'Marginal Direita' });
      this.locais.push({ value: 'MARGINAL_ESQUERDA', text: 'Marginal Esquerda' });
      this.locais.push({ value: 'DISPOSITIVO', text: 'Dispositivo' });

      // Fill Faixas
      this.faixas.push({ value: 'FAIXA_1', text: '1' });
      this.faixas.push({ value: 'FAIXA_2', text: '2' });
      this.faixas.push({ value: 'FAIXA_3', text: '3' });
      this.faixas.push({ value: 'FAIXA_4', text: '4' });
      this.faixas.push({ value: 'FAIXA_5', text: '5' });
      this.faixas.push({ value: 'FAIXA_6', text: '6' });
      this.faixas.push({ value: 'ACOSTAMENTO_INTERNO', text: 'Acostamento Interno' });
      this.faixas.push({ value: 'ACOSTAMENTO_EXTERNO', text: 'Acostamento Externo' });

      // Fill Nomenclaturas : Ramos
      for (let i = 100; i <= 900; i += 100) {
        this.alcas.push({ value: 'RAMO_' + i, text: 'Ramo ' + i });
      }

      // Fill Nomenclaturas : Rotatórias
      for (let i = 100; i <= 900; i += 100) {
        this.alcas.push({ value: 'ROTATORIA_', text: 'Rotatória ' + i });
      }

      // REST GET para dados Back-End
      Vue.axios.get(`/faixasDominio/${sigla}/dados`, options).then(response => {
        const rowData = response.data;

        // Áreas
        if (rowData.areas) {
          for (var area of rowData.areas) {
            this.areas.push({ value: area.id, text: area.descricao });
          }
        }

        // Tipos
        if (rowData.tipos) {
          for (var tipo of rowData.tipos) {
            if (window.config.SIGLA_ASSETS === 'epr') {
              if (tipo.descricao.includes('[FASE I]')) {
                this.tipos.push({ value: tipo, text: tipo.descricao });
              } else if (tipo.descricao.includes('[FASE II]')) {
                this.tipos.push({ value: tipo, text: tipo.descricao, disabled: true });
              } else if (tipo.descricao.includes('[FASE III]')) {
                this.tipos.push({ value: tipo, text: tipo.descricao, disabled: true });
              } else {
                this.tipos.push({ value: tipo, text: tipo.descricao });
              }
            } else {
              this.tipos.push({ value: tipo, text: tipo.descricao });
            }
          }
        }

        // Tipos
        if (rowData.objetivos) {
          for (var obj of rowData.objetivos) {
            this.objetivos.push({ value: obj, text: obj.descricao });
          }
        }

        // Rodovias
        this.rodovias = rowData.rodovias;

        // Trechos
        this.trechos = rowData.trechos;

        // Cidades
        this.cidades = rowData.cidades;
      });
    },
    getCidades() {
      const cidadesData = [];
      for (const cidade of this.cidades) {
        if (this.trecho != null && this.trecho !== undefined) {
          if (cidade.idTrecho != this.trecho) {
            continue;
          }
        }

        cidadesData.push({
          value: cidade,
          text: cidade.cidade.descricao + ' - [Km ' + cidade.kmInicial + ' ao Km ' + cidade.kmFinal + ']'
        });
      }

      cidadesData.sort((a, b) => {
        let cmp = a.value.kmInicial - b.value.kmInicial;
        if (cmp == 0) {
          cmp = ('' + a).localeCompare(b);
        }

        return cmp;
      });

      return cidadesData;
    },
    getCidadesByRodovia(rodovia) {
      const cidadesData = [];
      for (const cidade of this.cidades) {
        if (cidade.trecho.rodovia.descricao === rodovia) {
          cidadesData.push({
            value: cidade,
            text: cidade.cidade.descricao + ' - [Km ' + cidade.kmInicial + ' ao Km ' + cidade.kmFinal + ']'
          });
        }
      }

      cidadesData.sort((a, b) => {
        let cmp = a.value.kmInicial - b.value.kmInicial;
        if (cmp == 0) {
          cmp = ('' + a).localeCompare(b);
        }

        return cmp;
      });

      return cidadesData;
    },
    getGruposByObjetivos() {
      // Agrupa os Objetivos pelos Tipos (Demanda, Faixa Domínio)
      const data = [];

      const tipoDem = 'Demanda';
      const tipoFxa = 'Faixa Domínio';

      if (this.objetivos != null) {
        for (const objetivo of this.objetivos) {
          if (objetivo.value == null) continue;

          let tipoGrp = '';
          if (objetivo.value.grupo === 1) tipoGrp = tipoDem;
          else if (objetivo.value.grupo === 2) tipoGrp = tipoFxa;

          let found = false;
          for (var grpObj of data) {
            if (grpObj === tipoGrp) {
              found = true;
            }
          }

          if (!found) data.push(tipoGrp);
        }
      }
      return data;
    },
    getObjetivosByGrupo() {
      const data = [];
      const grupo = 2; // faixa domínio

      if (this.objetivos != null) {
        for (const objetivo of this.objetivos) {
          if (objetivo.value == null) continue;

          if (objetivo.value.grupo === grupo) {
            data.push(objetivo);
          }
        }
      }

      return data;
    },
    getRodoviasByTrechos() {
      const rodovias = [];
      for (const trecho of this.trechos) {
        let found = false;
        for (const rodovia of rodovias) {
          if (rodovia == trecho.rodovia.descricao) {
            found = true;
            break;
          }
        }

        if (!found) {
          rodovias.push(trecho.rodovia.descricao);
        }
      }

      return rodovias;
    },
    getTipos() {
      const data = [];
      data.push({ value: null, text: this.labelSelecionarTipo });

      for (const tipo of this.tipos) {
        if (tipo.value == null) continue;

        if (this.objetivo != undefined) {
          if (tipo.value.idObjetivo === this.objetivo) data.push(tipo);
        }
      }

      return data;
    },
    getTrechosByRodovia(rodovia) {
      const trechosData = [];

      for (const trecho of this.trechos) {
        if (trecho.rodovia == null || trecho.rodovia == undefined) {
          continue;
        }

        if (trecho.rodovia.descricao === rodovia) {
          if (this.trechoCidade != null) {
            if (this.trechoCidade == trecho.id) {
              trechosData.push(trecho);
            }
          } else {
            // cidade nao escolhida, carrega todos
            trechosData.push(trecho);
          }
        }
      }

      return trechosData;
    },
    getTrechoInfo(trecho) {
      if (trecho == null) {
        return null;
      }

      let trechoOutput = trecho.descricao;
      if (trecho.kmInicial != null && trecho.kmFinal != null) {
        trechoOutput +=
          ' - [KM ' +
          numeral(trecho.kmInicial).format('0.[00]') +
          ' ao KM ' +
          numeral(trecho.kmFinal).format('0.[00]') +
          ']';
      }

      return trechoOutput;
    },
    handleSelectCidade() {
      this.trechoCidade = null;
      for (const cid of this.cidades) {
        if (cid.id === this.cidade.id) {
          this.trechoCidade = cid.idTrecho;
          break;
        }
      }

      if (this.trechoCidade != null) {
        if (this.trecho == null || this.trecho !== this.trechoCidade) {
          this.trecho = this.trechoCidade;
        }
      }
    },
    hasHiddenField(fieldName) {
      if (this.hiddenFields == undefined || this.hiddenFields == null) return true;

      let hiddenFields = false;

      for (const value of this.hiddenFields) {
        if (value == fieldName) {
          hiddenFields = true;
        }
      }

      return hiddenFields;
    },
    hideModal() {
      this.$bvModal.hide('confirm-modal');
      this.$router.push('/');
    },
    hideModalError() {
      this.$bvModal.hide('error-modal');
    },
    onSubmit() {
      this.errorKmInicial = null;
      this.errorKmFinal = null;

      this.busySave = true;

      this.$validator.validateAll().then(result => {
        if (!result) {
          this.busySave = false;
          return;
        }

        const datSolicitacao = new Date();

        // Form submit logic
        const sigla = this.$store.state.empresa.empresaAtual.sigla;
        const token = this.$store.state.account.user;
        const requestOpts = {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        };

        const formData = new FormData();
        formData.append('datHorSolicitacao', moment(datSolicitacao).format('YYYY-MM-DD HH:mm:ss'));
        formData.append('titulo', this.titulo);

        if (this.tipo != undefined) formData.append('tipo', this.tipo.id);

        if (this.area != undefined) formData.append('area', this.area);

        if (this.objetivo != undefined) formData.append('objetivo', this.objetivo);

        if (this.trecho != undefined) formData.append('trecho', this.trecho);

        if (this.sentido != undefined) formData.append('sentido', this.sentido);

        if (this.cidade != undefined) formData.append('cidade', this.cidade.idCidade);

        if (this.kmInicial != undefined) formData.append('kmInicial', this.kmInicial);

        if (this.kmFinal != undefined) formData.append('kmFinal', this.kmFinal);

        if (this.local != undefined) formData.append('local', this.local);

        if (this.alca != undefined) formData.append('alca', this.alca);

        if (this.faixa != undefined) formData.append('faixa', this.faixa);

        if (this.observacao != undefined) formData.append('observacoes', this.observacao);

        formData.append('grupo', 2);

        for (var i = 0; i < this.files.length; i++) {
          formData.append('files', this.files[i]);
        }

        for (var x = 0; x < this.tiposAnexo.length; x++) {
          formData.append('tiposArquivos', this.tiposAnexo[x]);
        }

        Vue.axios
          .post(`/faixasDominio/${sigla}`, formData, requestOpts)
          .then(response => {
            this.protocolo = response.data.nroProtocolo;

            if (this.protocolo == null || this.protocolo == undefined) {
              this.$bvModal.show('error-modal');
            } else {
              this.$bvModal.show('confirm-modal');

              this.$notify({
                group: 'msgs',
                title: 'Confirmação',
                text: 'Solicitação incluída com sucesso.'
              });

              this.busySave = false;
            }
          })
          .catch(error => {
            const errorMsg = error.response ? error.response.data.message : error;

            if (errorMsg.toLowerCase().indexOf('km inicial') != -1) {
              this.errorKmInicial = 'Trecho: Erro Km Inicial';
              this.errorKmFinal = 'Trecho: Erro Km Final';
            }

            this.$notify({
              group: 'msgsCenter',
              title: 'Erro',
              text: errorMsg,
              type: 'error'
            });

            this.busySave = false;
          });
      });
    },
    onUploadAnexos(file, sequencia) {
      console.log(file, sequencia)
      if (file != undefined) {
        this.files.push(file);
      }

      console.log('onUploadAnexos=>', this.files);

      // this.tiposAnexo = [];
      //for (var i = 0; i < this.files.length; i++) {
      this.tiposAnexo.push(sequencia);
      //}

      
      console.log(this.tiposAnexo)
    },
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    getImagemBackgound() {
      this.backgroundTopoEmpresa = "background-image: url('img/" + this.empresaAtual.sigla + "/solicitacao.jpg')";
      // console.log(this.backgroundTopoEmpresa)
    },
    getTiposArquivos() {
      const token = this.$store.state.account.user;
      const options = { headers: { Authorization: `Bearer ${token}` } };

      this.anexos = [];

      console.log('tipo.idAnexo ==>', this.tipo.idAnexo);

      if (this.tipo !== null) {
        Vue.axios.get(`/demandas/${this.empresaAtual.sigla}/tipos/${this.tipo.id}`, options).then(response => {
          console.log('getTiposArquivos()=>', response.data);
          const arquivos = response.data.arquivos;
          if (arquivos.length > 0) {
            for (const arquivo of arquivos) {
              this.anexos.push(arquivo);
            }
          }
        });
      }
    },
    getNomeAnexo(tipoArquivo) {
      if (this.anexos == null || this.anexos.length === 0) return null;

      // for (const anexo of this.anexos) if (anexo.tipoAtivo === tipoAtivo) return anexo.anexo.nome;
      console.log('tipoArquivo =>', tipoArquivo);

      return null;
    },
    hasArquivo(tipoAtivo) {
      if (this.anexos == null || this.anexos.length === 0) return false;

      for (const anexo of this.anexos) if (anexo.tipoAtivo === tipoAtivo) return true;

      return false;
    },
    getTipoArquivo(item) {
      let text = '';

      for (const arquivo of this.optionsArquivo) {
        if (arquivo.id === item.tipoArquivo) {
          text = arquivo.descricao;
        }
      }

      return text;
    },
    getDownloadAnexoTipo() {
      const token = this.$store.state.account.user;

      if (this.tipo.idAnexo != undefined) {
        const options = { headers: { Authorization: `Bearer ${token}` } };
        Vue.axios
          .get(`/ged/${this.empresaAtual.sigla}/${this.tipo.idAnexo}`, options)
          .then(response => {
            console.log('getDownloadAnexoTipo() => ', response.data);
            let arquivo = response.data;

            if (arquivo.uuid !== undefined) {
              this.executeDownloadAnexo(arquivo);
            }
          })
          .catch(error => {
            this.showErrorNotify('Erro', error.data ? error.data : error);
          });
      }
    },

    executeDownloadAnexo(anexo) {
      const token = this.$store.state.account.user;
      const siglaEmpresa = this.empresaAtual.sigla;
      const modulo = 'DEM';

      const requestCfg = {
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      };

      Vue.axios
        .get(`/downloads/${siglaEmpresa}/anexos/${modulo}/${anexo.uuid}`, requestCfg)
        .then(response => {
          console.log('executeDownloadAnexo...');
          this.downloadFile(new Blob([response.data]), anexo.nome);
        })
        .catch(error => {
          this.showErrorNotify('Erro', error.data ? error.data : error);
        });
    },

    getTypeFile(typeFile) {
      let type = '';

      if (typeFile === 1) {
        // Arquivo PDF
        type = '.pdf';
      } else if (typeFile === 2) {
        // Documento de Texto (DOCX)
        type = '.doc, .docx';
      } else if (typeFile === 3) {
        // Planilha Eletrônica (XLSX)
        type = '.xlsx, .xlsm, .xls, .csv';
      } else if (typeFile === 4) {
        // Imagem (PNG/JPG/BMP)
        type = 'image/*';
      } else if (typeFile === 5) {
        // PowerPoint (PPT)
        type = '.pptx, .ppt';
      } else if (typeFile === 6) {
        // E-mail (MSG/EML)
        type = '.eml, .msg';
      } else if (typeFile === 7) {
        // Arquivo Compactado (ZIP/RAR)
        type = '.bzip2, .gzip, .tar, .wim, .7z';
      } else if (typeFile === 8) {
        // Projeto CAD (DWG/DXF)
        type = '.dwg, .dxf';
      } else if (typeFile === 9) {
        // Arquivo KMZ
        type = '.kml, .kmz, .csv, .tsv, .gpx, .xlsx';
      }

      return type;
    },

    downloadFile(blob, fileName) {
      const link = document.createElement('a');
      // create a blobURI pointing to our Blob
      link.href = URL.createObjectURL(blob);
      console.log('fileName==>', fileName);
      if (fileName) link.download = fileName;
      link.target = '_blank';
      // some browser needs the anchor to be in the doc
      document.body.appendChild(link);
      link.click();
      link.remove();
      // in case the Blob uses a lot of memory
      setTimeout(() => URL.revokeObjectURL(link.href), 6000);
    },

    openMapForChooseKm(km) {
      this.chooseKm = km;

      if (km == 'I') {
        this.loadingCoordRodKmInicial = true;
      } else {
        this.loadingCoordRodKmFinal = true;
      }

      this.coordenadasRodovia = [];
      const newPosition = {};
      let trechoAux = {};

      if (this.trecho !== undefined) {
        for (const trecho of this.trechos) {
          if (trecho.id == this.trecho) {
            trechoAux = trecho;
          }
        }
      }

      const token = this.$store.state.account.user;
      const options = { headers: { Authorization: `Bearer ${token}` } };

      Vue.axios
        .get(`/rodovias/${this.empresaAtual.sigla}/${trechoAux.rodovia.descricao}/coordenadas`, options)
        .then(response => {
          for (const coord of response.data) {
            this.coordenadasRodovia.push({
              lat: coord.latitude,
              lng: coord.longitude,
              km: parseFloat(coord.kilometro)
            });
          }

          for (const coord of this.coordenadasRodovia) {
            newPosition.lat = parseFloat(coord.lat);
            newPosition.lng = parseFloat(coord.lng);
            break;
          }

          this.mapOptions.center = newPosition;

          this.$bvModal.show('modalChooseKm');

          if (km == 'I') {
            this.loadingCoordRodKmInicial = false;
          } else {
            this.loadingCoordRodKmFinal = false;
          }
        });
    },

    ChooseKmMap(event) {
      const coordEvent = {
        lat: parseFloat(event.latLng.lat().toFixed(6)),
        lng: parseFloat(event.latLng.lng().toFixed(6))
      };

      const findKm = this.coordenadasRodovia.sort((a, b) => a.lat - b.lat).find(item => item.lat > coordEvent.lat);

      if (findKm !== undefined) {
        if (this.chooseKm == 'I') {
          if (findKm.km > 1000) {
            this.kmInicial = findKm.km / 1000;
          } else {
            this.kmInicial = findKm.km;
          }
        } else if (this.chooseKm == 'F') {
          if (findKm.km > 1000) {
            this.kmFinal = findKm.km / 1000;
          } else {
            this.kmFinal = findKm.km;
          }
        }

        this.$bvModal.hide('modalChooseKm');
      }
    },
    showAlertPagamento() {
      if (this.objetivo !== undefined) {
        for (const obj of this.objetivos) {
          if (obj.value !== null) {
            if (obj.value.id === this.objetivo) {
              if (obj.value.pagamento == 'S') {
                this.showPagamento = true;
                this.getRefCodeDemandas();
              } else {
                this.showPagamento = false;
              }
            }
          }
        }

        this.anexos = [];
      }
    },
    getRefCodeDemandas() {
      const token = this.$store.state.account.user;
      const options = { headers: { Authorization: `Bearer ${token}` } };

      const sigla = this.$store.state.empresa.empresaAtual.sigla;
      const refName = 'DEMANDA.PAGAMENTO_LINK';

      Vue.axios
        .get(`/core/refCodes/${sigla}?search=${refName}`, options)
        .then(response => {
          if (response.data.length != 0) {
            this.refCodeDemandas = response.data[0];
          }
        })
        .catch(error => {
          this.$notify({
            group: 'msgs',
            title: 'Catch',
            text: error,
            type: 'error'
          });
        });
    },
    getTipoModalSuccess() {
      //const token = this.$store.state.account.user;
      //const options = { headers: { Authorization: `Bearer ${token}` } };
      //Vue.axios
      //  .get(`/rodovias/${this.empresaAtual.sigla}/${trechoAux.rodovia.descricao}/coordenadas`, options)
      //  .then(response => {
      //  });
    }
  }
};
</script>
<style>
table {
  font-size: 0.8571em;
}
</style>
