<template>
  <div class="page-header clear-filter" filter-color="orange">
    <div class="page-header-image" :style="backgroundLogin"></div>
    <div class="content">
      <div class="container">
        <div class="col-md-5 ml-auto mr-auto">
          <card type="login" plain>
            <div slot="header" class="content-top brand">
              <h2 class="h2-seo description">Alterar Senha</h2>
            </div>

            <p class="lead description">
              Sua solicitação de alteração de senha ao faixa de domínio foi realizada com sucesso. Verifique a caixa de
              entrada do email para confirmação.
            </p>

            <template slot="raw-content">
              <div class="card-footer text-center">
                <b-btn class="btn-primary btn-round m-2" @click="handleVoltar">Voltar</b-btn>
              </div>
            </template>
          </card>
        </div>
      </div>
    </div>

    <main-footer></main-footer>
  </div>
</template>

<script lang="js">
import { Card } from '@/components';
import MainFooter from '@/layout/MainFooter';

export default {
  name: 'confirm-page',
  bodyClass: 'register-page',
  components: {
    Card,
    MainFooter
  },
  data() {
    return {
      backgroundLogin: "background-image: url('img/trevo.jpg')",
    }
  },
  methods:{
    handleVoltar() {
      this.$router.push('/login')
    }
  },
  mounted() {
    if (window.config && window.config.SIGLA_ASSETS) {
        const siglaAssets = window.config.SIGLA_ASSETS;
        this.backgroundLogin = `background-image: url('img/${siglaAssets}/trevo.jpg')`;
    }
  },
}
</script>
