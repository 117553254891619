<template>
  <div>
    <div class="page-header clear-filter" filter-color="green">
      <parallax class="page-header-image" :style="backgroundLogin"></parallax>
      <div class="container">
        <div class="content-center brand">
          <h1 class="h1-seo">FAIXA DE DOMÍNIO</h1>
          <h3>Portal para geração e acompanhamento de solicitações da faixa de domínio</h3>
          <b-row>
            <b-col>
              <router-link :to="{ name: 'pesquisaDemanda', params: { tipo: 'P' } }" class="btn btn-primary h6"
                >Pesquisar Solicitações</router-link
              >
              <router-link :to="{ name: 'novaDemanda' }" class="btn btn-success h6 ml-2">Nova Solicitação</router-link>
              <router-link :to="{ name: 'faleConosco', params: { tipo: 'P' } }" class="btn btn-neutral h6 ml-2"
                >Fale Conosco</router-link
              >
              <router-link :to="{ name: 'faqs', params: { tipo: 'P' } }" class="btn btn-neutral h6 ml-2"
                >Perguntas Frequentes</router-link
              >
            </b-col>
          </b-row>
        </div>
        <h6 class="category category-absolute direita">
          Solução
          <a class="ml-2" href="https://www.otisoftware.com.br" target="_blank">
            <img src="img/oti-slim.png" class="otisw-logo" />
          </a>
        </h6>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { Parallax } from '@/components';
import { mapState } from 'vuex';

export default {
  name: 'index',
  bodyClass: 'index-page',
  components: {
    Parallax
  },
  computed: {
    ...mapState('empresa', ['empresas', 'empresaAtual'])
  },
  data() {
    return {
      backgroundLogin: "background-image: url('img/trevo.jpg')",
      statusSel: null,
      status: [],
      demandas: []
    }
  },
  methods: {
    handleNone() {
      // métodos removidos após reavaliação ambientes
    }
  },
  mounted() {
    if (window.config && window.config.SIGLA_ASSETS) {
        const siglaAssets = window.config.SIGLA_ASSETS;
        this.backgroundLogin = `background-image: url('img/${siglaAssets}/trevo.jpg')`;
    }
  }
};
</script>
<style lang="scss">
.index-page .link-solicitacoes {
  top: 70vh;
}

.otisw-logo {
  max-width: 48px;
}
</style>
