<template>
  <footer class="footer" :class="{ [`footer-${type}`]: type }" :data-background-color="backgroundColor">
    <div class="container">
      <!-- <div class="copyright">
        &copy; {{ year }}, Designed by
        <a href="https:///www.invisionapp.com" target="_blank" rel="noopener"
          >Invision</a
        >. Coded by
        <a href="https://www.oti.inf.br/" target="_blank" rel="noopener"
          >OTI</a
        >.
      </div> -->
      <div>
        <b-row>
          <b-col cols="6">
            <div class="d-flex justity-start">
              <div v-if="empresaAtual != undefined && empresaAtual.siteTwitter != undefined">
                <a
                  class="nav-link"
                  rel="tooltip"
                  title="Siga-nos no Twitter"
                  data-placement="bottom"
                  :href="empresaAtual.siteTwitter"
                  target="_blank"
                >
                  <font-awesome-icon icon="fa-brands fa-x-twitter" />
                  <p class="d-lg-none d-xl-none">Twitter</p>
                </a>
              </div>

              <div v-if="empresaAtual != undefined && empresaAtual.siteFacebook != undefined">
                <a
                  class="nav-link"
                  rel="tooltip"
                  title="Curta-nos no Facebook"
                  data-placement="bottom"
                  :href="empresaAtual.siteFacebook"
                  target="_blank"
                >
                  <i class="fab fa-facebook-square"></i>
                  <p class="d-lg-none d-xl-none">Facebook</p>
                </a>
              </div>

              <div v-if="empresaAtual != undefined && empresaAtual.siteInstagram != undefined">
                <a
                  class="nav-link"
                  rel="tooltip"
                  title="Siga-nos no Instagram"
                  data-placement="bottom"
                  :href="empresaAtual.siteInstagram"
                  target="_blank"
                >
                  <i class="fab fa-instagram"></i>
                  <p class="d-lg-none d-xl-none">Instagram</p>
                </a>
              </div>

              <div v-if="empresaAtual != undefined && empresaAtual.siteLinkedin != undefined">
                <a
                  class="nav-link"
                  rel="tooltip"
                  title="Siga-nos no LinkedIn"
                  data-placement="bottom"
                  :href="empresaAtual.siteLinkedin"
                  target="_blank"
                >
                  <i class="fab fa-linkedin"></i>
                  <p class="d-lg-none d-xl-none">LinkedIn</p>
                </a>
              </div>

              <div v-if="empresaAtual != undefined && empresaAtual.siteYoutube != undefined">
                <a
                  class="nav-link"
                  rel="tooltip"
                  title="Siga-nos no Youtube"
                  data-placement="bottom"
                  :href="empresaAtual.siteYoutube"
                  target="_blank"
                >
                  <i class="fab fa-youtube"></i>
                  <p class="d-lg-none d-xl-none">Youtube</p>
                </a>
              </div>

              <div v-if="empresaAtual != undefined && empresaAtual.siteTiktok != undefined">
                <a
                  class="nav-link"
                  rel="tooltip"
                  title="Siga-nos no Tiktik"
                  data-placement="bottom"
                  :href="empresaAtual.siteTiktok"
                  target="_blank"
                >
                  <font-awesome-icon icon="fa-brands fa-tiktok" />
                  <p class="d-lg-none d-xl-none">Tiktok</p>
                </a>
              </div>
            </div>
          </b-col>

          <b-col cols="6">
            <h6 class="category category-absolute direita">
              Solução
              <a class="ml-2" href="https://www.otisoftware.com.br" target="_blank">
                <img src="img/oti-slim.png" class="otisw-logo" />
              </a>
            </h6>
          </b-col>
        </b-row>
      </div>
    </div>
  </footer>
</template>
<script>
import { mapState } from 'vuex';

export default {
  props: {
    backgroundColor: String,
    type: String
  },
  computed: {
    ...mapState('empresa', ['empresas', 'empresaAtual'])
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
